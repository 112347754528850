import React, { Fragment, useState } from "react";
import Home365_Logo from "./Media/Home365_Logo.svg";
import "./App.css";
import { Button, Drawer, Form, Input, Layout, Tag } from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { useMediaQuery } from "react-responsive";
import Cleave from "cleave.js/react";
import shortid from "shortid";
import axios from "axios";
import { ReactSVG } from "react-svg";
import { CheckCircleOutlined } from "@ant-design/icons";

import Feedback1_Image from "./Media/Feedback1.png";
import Feedback2_Image from "./Media/Feedback2.png";
import Feedback3_Image from "./Media/Feedback3.png";

import Background_Check_Icon from "./Media/1_Background_Check_Icon.svg";
import Credit_Check_Icon from "./Media/2_Credit_Check_Icon.svg";
import Tribunal_Check_Icon from "./Media/3_Tribunal_Check_Icon.svg";

import Legal_Compliance_Icon from "./Media/4_Legal_Compliance_Icon.svg";
import Repair_Maintenance_Icon from "./Media/5_Repair_Maintenance_Icon.svg";
import Meth_Protection_Icon from "./Media/6_Meth_Protection_Icon.svg";

import Inspection_Icon from "./Media/7_Inspection_Icon.svg";
import Investment_Return_Icon from "./Media/8_Investment_Return_Icon.svg";
import Legal_Support_Icon from "./Media/9_Legal_Support_Icon.svg";

import Good_Tenant_Image from "./Media/Good_Tenant_Image.png";
import Takecare_YourProperty from "./Media/Takecare_YourProperty.png";
import Stress_Free_Management from "./Media/Stress_Free_Management.png";

import moment from "moment";
import { hotjar } from "react-hotjar";

{
  hotjar.initialize(2672560, 6);
}

const { TextArea } = Input;

axios.defaults.baseURL = "https://api.passonline.co.nz/api";
// axios.defaults.baseURL = "http://localhost:5000/api";

// "https://api.devpassonline.com/api",

const App = () => {
  const IsCellphone = useMediaQuery({
    query: "(max-device-width: 599px)",
  });

  // const IsTablet = useMediaQuery({
  //   query: "(min-device-width: 600px)",
  // });

  // const IsLargeDevice = useMediaQuery({
  //   query: "(min-device-width: 1200px)",
  // });

  const buttonId1 = "Get-your-FREE-appraisal-now";
  const buttonId2 = "Submit";
  const buttonId3 = "OK";

  const CallToActionButton = (alignSelfCenterPosition: string) => {
    return (
      <Button
        id={buttonId1}
        style={{
          background: "#FFC700",
          border: IsCellphone ? "2px solid #000000" : "4px solid #000000",
          borderRadius: IsCellphone ? 16 : 12,
          padding: IsCellphone ? "14px 32px" : "16px 32px",
          fontSize: IsCellphone ? 16 : 24,
          fontWeight: IsCellphone ? 700 : 700,
          width: "fit-content",
          height: "fit-content",
          alignSelf: alignSelfCenterPosition,
          lineHeight: "120%",
        }}
        onClick={() => {
          setIsShowContactUsDrawer(true);
        }}
      >
        <span id={buttonId1}> Get your FREE appraisal now</span>
      </Button>
    );
  };

  const Logo = (width?: number, height?: number) => {
    return (
      <img
        loading={"lazy"}
        src={Home365_Logo}
        alt="logo"
        style={{
          width: width !== undefined ? width : IsCellphone ? 148 : 163,
          height: height !== undefined ? height : IsCellphone ? 40 : 44,
          marginBottom: 8,
        }}
      />
    );
  };

  const FeedBack1 = () => {
    let address = "1/37 Eversleigh Road, Belmont";
    let comment =
      '"I would recommend Home365 to any property owner because I have 100% confidence Home365 will look after my property while I am not in Auckland." -- Luna';
    let color = "#E6DDF5";
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 24,
        }}
      >
        <img
          loading={"lazy"}
          src={Feedback2_Image}
          alt={address}
          style={{
            width: IsCellphone ? undefined : 330,
            height: IsCellphone ? 208 : 221,
          }}
        />

        <Tag
          color={color}
          style={{
            padding: "4px 8px",
            width: "fit-content",
            marginTop: IsCellphone ? 16 : 24,
            marginBottom: 8,
            color: "#000000",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {address}
        </Tag>
        <div
          style={{
            width: IsCellphone ? undefined : 330,
          }}
        >
          {comment}
        </div>
      </div>
    );
  };

  const FeedBack2 = () => {
    let address = "1/91 Nile Road, Milford";
    let comment =
      "“Very good property manager, got me a very good rental return, and found a good tenant within a reasonable time.” -- Annie";
    let color = "#FFEAAA";
    return (
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 24 }}
      >
        <img
          loading={"lazy"}
          src={Feedback1_Image}
          alt={address}
          style={{
            width: IsCellphone ? undefined : 330,
            height: IsCellphone ? 208 : 221,
          }}
        />

        <Tag
          color={color}
          style={{
            padding: "4px 8px",
            width: "fit-content",
            marginTop: IsCellphone ? 16 : 24,
            marginBottom: 8,
            color: "#000000",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {address}
        </Tag>
        <div
          style={{
            width: IsCellphone ? undefined : 330,
          }}
        >
          {comment}
        </div>
      </div>
    );
  };

  const FeedBack3 = () => {
    let address = "260 Lake Road, Narrow Neck";
    let comment =
      '"Great team always helpful. Any maintenance is done pretty quickly, no hassle at all and always spot on!" -- Joe';
    let color = "#D6F8D7";
    return (
      <div
        style={{ display: "flex", flexDirection: "column", marginBottom: 24 }}
      >
        <img
          loading={"lazy"}
          src={Feedback3_Image}
          alt={address}
          style={{
            width: IsCellphone ? undefined : 330,
            height: IsCellphone ? 208 : 221,
          }}
        />

        <Tag
          color={color}
          style={{
            padding: "4px 8px",
            width: "fit-content",
            marginTop: IsCellphone ? 16 : 24,
            marginBottom: 8,
            color: "#000000",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {address}
        </Tag>
        <div
          style={{
            width: IsCellphone ? undefined : 330,
          }}
        >
          {comment}
        </div>
      </div>
    );
  };

  const [isShowContactUsDrawer, setIsShowContactUsDrawer] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRender, setIsRerender] = useState<string>(shortid());

  const [contactUsForm] = Form.useForm();

  const IsAllDataFilled = (): boolean => {
    let result = true;

    if (
      contactUsForm.getFieldValue("name") === "" ||
      contactUsForm.getFieldValue("name") === null ||
      contactUsForm.getFieldValue("name") === undefined
    ) {
      result = false;
    }
    if (
      contactUsForm.getFieldValue("phone") === "" ||
      contactUsForm.getFieldValue("phone") === null ||
      contactUsForm.getFieldValue("phone") === undefined
    ) {
      result = false;
    }
    return result;
  };

  const OnCloaseHandler = () => {
    setIsShowContactUsDrawer(false);
    contactUsForm.resetFields();
    setHasConsultationBooked(false);
    setIsLoading(false);
  };

  const config = {
    headers: { Authorization: `Bearer ` },
  };

  const [hasConsultationBooked, setHasConsultationBooked] =
    useState<boolean>(false);

  const onFinish = async () => {
    let anyError = false;
    try {
      const values = await contactUsForm.validateFields();
    } catch (errorInfo) {
      anyError = true;
    }

    if (anyError === false) {
      const bodyParameters = {
        Message:
          contactUsForm.getFieldValue("note") === null ||
          contactUsForm.getFieldValue("note") === undefined
            ? ""
            : contactUsForm.getFieldValue("note"),
        ClientName: contactUsForm.getFieldValue("name"),
        ClientEmail: "",
        ClientPhone: contactUsForm.getFieldValue("phone"),
        FromWhichPage: "Home365",
      };

      axios
        .post("/beforelogin/ContactUsForm", bodyParameters)
        .then((response) => {
          setIsLoading(false);
          setHasConsultationBooked(true);
        })
        .catch((error) => {
          // eslint-disable-next-line no-lone-blocks
          {
            console.log(error);
            setIsLoading(false);
            Object.values(error.response.data.errors)
              .flat()
              .map((err, i) => console.log(err));
          }
        });
    }
  };

  return (
    <div style={{ fontFamily: "Inter" }}>
      {isShowContactUsDrawer && (
        <Drawer
          visible={isShowContactUsDrawer}
          onClose={() => {
            OnCloaseHandler();
          }}
          width={IsCellphone ? "100%" : "30vw"}
          height={IsCellphone ? "85vh" : "100%"}
          placement={IsCellphone ? "bottom" : undefined}
          closeIcon={
            IsCellphone || hasConsultationBooked ? <div></div> : undefined
          }
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Inter",

            overflow: "scroll",
            padding: "24px 24px",
          }}
          maskClosable={hasConsultationBooked ? false : true}
        >
          {hasConsultationBooked ? (
            <Fragment>
              <div
                style={{
                  paddingTop: IsCellphone ? 60 : 24,
                  position: "sticky",
                  top: 0,
                  fontSize: 32,
                  fontWeight: 600,
                  color: "#009D52",
                  background: "#FFFFFF",
                  zIndex: 900,
                  marginBottom: 16,
                  lineHeight: "100%",
                }}
              >
                <CheckCircleOutlined
                  style={{
                    color: "#009D52",
                    fontWeight: 600,
                    fontSize: 48,
                    marginBottom: 12,
                  }}
                />
                <div style={{ lineHeight: "130%" }}>
                  Thank you!<br></br>
                  {contactUsForm.getFieldValue("name")}
                  <br></br>
                  {contactUsForm.getFieldValue("phone")}
                  <br></br>
                </div>
              </div>
              <div
                style={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: "#B9C2D1",
                  marginBottom: 16,
                }}
              >
                <div>We will call you back soon</div>
              </div>
              <Button
                loading={isLoading}
                id={buttonId3}
                type="primary"
                size="large"
                style={{
                  height: "55px",
                  width: 176,
                  background: "#009D52",
                  borderRadius: 12,
                  fontWeight: "bold",
                  fontSize: "18px",

                  fontStyle: "normal",
                  color: "#FFFFFF",
                }}
                onClick={() => {
                  setIsLoading(true);
                  setTimeout(() => {
                    OnCloaseHandler();
                  }, 500);
                }}
              >
                <span id={buttonId3}>Ok</span>
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <div style={{ width: 246 }}>
                <div
                  style={{
                    fontSize: 32,
                    fontWeight: 800,
                    letterSpacing: "-0.01em",
                    color: "#009D52",
                    lineHeight: "130%",
                    marginBottom: 16,
                  }}
                >
                  <div>Get your FREE</div>
                  <div>appraisal now</div>
                </div>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#666666",
                    lineHeight: "150%",
                    marginBottom: 33,
                  }}
                >
                  Lease us your number and we will call you back soon.
                </div>
              </div>
              <Form
                form={contactUsForm}
                name="consultationForm"
                onFinish={() => {
                  setIsLoading(true);
                  setTimeout(() => {
                    onFinish();
                  }, 1000);
                }}
                scrollToFirstError
              >
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Name is required" }]}
                >
                  <Input
                    placeholder="Name"
                    style={{
                      width: 246,
                      height: 48,
                      borderRadius: 8,
                    }}
                    onChange={() => {
                      setIsRerender(shortid());
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    { required: true, message: "Mobile phone is required" },
                  ]}
                >
                  <Cleave
                    style={{
                      width: 246,
                      height: 48,
                      borderRadius: 8,
                    }}
                    className="ant-input"
                    placeholder="Mobile phone number"
                    options={{
                      blocks: [3, 3, 6],
                      delimiter: "-",
                      numericOnly: true,
                    }}
                    onChange={() => {
                      setIsRerender(shortid());
                    }}
                  />
                </Form.Item>
                <Form.Item name="note">
                  <TextArea
                    rows={4}
                    placeholder="(optional) Leave us a message, e.g. rental address"
                    style={{
                      borderRadius: 8,
                      width: 246,
                    }}
                  />
                </Form.Item>

                <Form.Item style={{ marginBottom: 8 }}>
                  <Button
                    loading={isLoading}
                    id={buttonId2}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    style={{
                      height: "55px",
                      width: 176,
                      background: IsAllDataFilled() ? "#009D52" : "#E0E0E0",
                      borderRadius: 12,
                      fontWeight: "bold",
                      fontSize: "18px",
                      color: "#FFFFFF",
                      border: "0px",
                    }}
                  >
                    <span id={buttonId2}>Book my call</span>
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="link"
                    size="large"
                    style={{
                      fontWeight: 600,
                      fontSize: 18,
                      color: "#000000",
                    }}
                    onClick={() => {
                      OnCloaseHandler();
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                </Form.Item>
              </Form>
            </Fragment>
          )}
        </Drawer>
      )}
      <Layout>
        <Header
          style={{
            height: 96,
            background: "#FFFFFF",
            padding: IsCellphone ? "0px 30px" : undefined,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: IsCellphone ? undefined : "space-around",
              padding: "24px 0px 24px 0px",
            }}
          >
            {Logo()}

            {IsCellphone ? null : (
              <Button
                id={buttonId1}
                style={{
                  border: "2px solid #009D52",
                  borderRadius: 8,
                  background: "#FFFFFF",
                  color: "#009D52",
                  fontSize: 18,
                  fontWeight: 600,
                  padding: "8px 16px",
                  width: "fit-content",
                  height: "fit-content",
                  lineHeight: "150%",
                }}
                onClick={() => {
                  setIsShowContactUsDrawer(true);
                }}
              >
                <span id={buttonId1}> Get your FREE appraisal now</span>
              </Button>
            )}
          </div>
        </Header>
        <Content style={{ background: "#FFFFFF" }}>
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 255, 133, 0.3) 0%, rgba(0, 157, 82, 0) 100%)",
              padding: IsCellphone ? "64px 32px" : "72px 200px",
              display: "flex",
              flexDirection: "column",
              fontSize: IsCellphone ? 32 : 84,
              fontWeight: IsCellphone ? 800 : 900,
              letterSpacing: "-0.02em",
              lineHeight: "115%",
              alignItems: IsCellphone ? "start" : "center",
            }}
          >
            <div
              style={{
                backgroundImage:
                  "linear-gradient(180deg, #007D41 0%, #6CAE00 100%)",
                WebkitTextFillColor: "transparent",
                WebkitBackgroundClip: "text",
                textAlign: IsCellphone ? "start" : "center",
                marginBottom: 24,
              }}
            >
              <div>Rent your property</div>
              <div>stress-free</div>
            </div>
            <div
              style={{
                fontSize: IsCellphone ? 18 : 32,
                fontWeight: IsCellphone ? 700 : 600,
                textAlign: IsCellphone ? "start" : "center",
                marginBottom: 24,
                lineHeight: "150%",
                letterSpacing: "0em",
              }}
            >
              Home365 takes care of your property like it's our own.
            </div>
            {CallToActionButton("center")}
          </div>
          <div
            style={{
              padding: IsCellphone ? "64px 32px" : "72px 200px",
              display: "flex",
              flexDirection: "column",
              background: "#FFFFFF",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: IsCellphone ? "column" : "row",
                fontSize: IsCellphone ? 32 : 48,
                fontWeight: IsCellphone ? 800 : 900,
                justifyContent: IsCellphone ? "start" : "center",
                alignItems: IsCellphone ? "start" : "center",
              }}
            >
              {Logo()}
              <div
                style={{
                  marginLeft: IsCellphone ? undefined : 10,
                }}
              >
                happy clients
              </div>
            </div>
            <div
              style={{
                marginTop: IsCellphone ? 24 : 48,
                display: "flex",
                flexDirection: "row",
                flexFlow: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {FeedBack1()}
              {FeedBack2()}
              {FeedBack3()}
            </div>
          </div>
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 157, 82, 0) 0%, rgba(0, 255, 133, 0.3) 100%)",
              padding: IsCellphone ? "64px 32px" : "72px 200px",
              display: "flex",
              flexDirection: IsCellphone ? "column" : "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div style={{ alignSelf: IsCellphone ? undefined : "center" }}>
              {
                <img
                  loading={"lazy"}
                  src={Good_Tenant_Image}
                  alt={"Home365 Good Tenant Check"}
                  style={{
                    width: IsCellphone ? 311 : 508,
                    height: IsCellphone ? 269 : 440,
                    marginBottom: IsCellphone ? 40 : undefined,
                  }}
                ></img>
              }
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: IsCellphone ? 311 : 419,
              }}
            >
              {Logo()}{" "}
              <div
                style={{
                  fontWeight: 800,
                  fontSize: 32,
                  letterSpacing: "-0.01em",
                  marginBottom: 48,
                  lineHeight: "120%",
                }}
              >
                only good tenants for you
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Background_Check_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Background Check
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: 24,
                }}
              >
                This check will include verifying identity, gathering references
                from employers and former landlords, criminal history, eviction
                history.
              </div>
              {/* ------------------------------------- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Credit_Check_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Credit Check
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: 24,
                }}
              >
                We thoroughly review all prospective tenants using
                industry-leading credit checks.
              </div>
              {/* ------------------------------------- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Tribunal_Check_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Tenancy Tribunal Check
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: IsCellphone ? 40 : 48,
                }}
              >
                We check over 10+ years' worth of tenancy tribunal orders to
                identify tenancy issues.
              </div>
              {CallToActionButton(IsCellphone ? "center" : "start")}
            </div>
          </div>

          <div
            style={{
              background: "#FFFFFF",
              padding: IsCellphone ? "64px 32px" : "72px 200px",
              display: "flex",
              flexDirection: IsCellphone ? "column-reverse" : "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: IsCellphone ? 311 : 419,
              }}
            >
              {Logo()}{" "}
              <div
                style={{
                  fontWeight: 800,
                  fontSize: 32,
                  letterSpacing: "-0.01em",
                  marginBottom: 48,
                  lineHeight: "120%",
                }}
              >
                takes care of your property like it’s our own
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Legal_Compliance_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Legal Compliance
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: 24,
                }}
              >
                100% meeting the Healthy Homes Standards. We check property
                compliance to regulations for insulation, ventilation, drainage
                and more.
              </div>
              {/* ------------------------------------- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Repair_Maintenance_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Repair & Maintenance
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: 24,
                }}
              >
                Working with a team of professionals to ensure we address
                repairs and maintenance issues in a timely and cost effective
                manner.
              </div>
              {/* ------------------------------------- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Meth_Protection_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Meth / P Detection
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: IsCellphone ? 40 : 48,
                }}
              >
                Home365 protects your property safe and clean. That’s why we
                offer an investment property meth testing service.
              </div>
              {CallToActionButton(IsCellphone ? "center" : "start")}
            </div>
            <div style={{ alignSelf: IsCellphone ? undefined : "center" }}>
              {
                <img
                  alt={"Home365 Take care of your property"}
                  loading={"lazy"}
                  src={Takecare_YourProperty}
                  style={{
                    width: IsCellphone ? 311 : 508,
                    height: IsCellphone ? 269 : 440,
                    marginBottom: IsCellphone ? 40 : undefined,
                  }}
                ></img>
              }
            </div>
          </div>

          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(0, 157, 82, 0) 0%, rgba(0, 255, 133, 0.3) 100%)",
              padding: IsCellphone ? "64px 32px" : "72px 200px",
              display: "flex",
              flexDirection: IsCellphone ? "column" : "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div style={{ alignSelf: IsCellphone ? undefined : "center" }}>
              {
                <img
                  alt={"Home365 Stress Free Rental Property Management"}
                  loading={"lazy"}
                  src={Stress_Free_Management}
                  style={{
                    width: IsCellphone ? 311 : 508,
                    height: IsCellphone ? 269 : 440,
                    marginBottom: IsCellphone ? 40 : undefined,
                  }}
                ></img>
              }
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: IsCellphone ? 311 : 419,
              }}
            >
              {Logo()}{" "}
              <div
                style={{
                  fontWeight: 800,
                  fontSize: 32,
                  letterSpacing: "-0.01em",
                  marginBottom: 48,
                  lineHeight: "120%",
                }}
              >
                stress-free management
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Inspection_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  On Time Inspection
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: 24,
                }}
              >
                Enjoy peace of mind knowing your property is kept in good
                condition. We provide extensive condition reports, including
                photos.
              </div>
              {/* ------------------------------------- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Investment_Return_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />

                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Investment Returns
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: 24,
                }}
              >
                Our streamlined & digitally enabled process enables efficient
                and robust screening to find you the best long-term tenants.
              </div>
              {/* ------------------------------------- */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 12,
                }}
              >
                <ReactSVG
                  src={Legal_Support_Icon}
                  style={{ width: 40, height: 40, marginRight: 8 }}
                />
                <div
                  style={{
                    fontSize: IsCellphone ? 24 : 32,
                    fontWeight: IsCellphone ? 700 : 800,
                    letterSpacing: "-0.04em",
                    color: "#000000",
                  }}
                >
                  Legal Support
                </div>
              </div>
              <div
                style={{
                  color: "#666666",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "150%",
                  marginBottom: IsCellphone ? 40 : 48,
                }}
              >
                We do the hard work for you by proactively communicating the
                latest changes in legislation.
              </div>
              {CallToActionButton(IsCellphone ? "center" : "start")}
            </div>
          </div>

          <div
            style={{
              padding: IsCellphone ? "128px 32px" : "200px 200px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#FFFFFF",
            }}
          >
            {Logo(IsCellphone ? 150 : 352, IsCellphone ? 40 : 95)}
            <div
              style={{
                backgroundImage:
                  "linear-gradient(180deg, #007D41 0%, #6CAE00 100%)",
                WebkitTextFillColor: "transparent",
                WebkitBackgroundClip: "text",
                textAlign: IsCellphone ? "start" : "center",
                fontSize: IsCellphone ? 32 : 84,
                fontWeight: IsCellphone ? 800 : 900,
                letterSpacing: "-0.01em",
                lineHeight: "115%",
              }}
            >
              <div>Rent your property</div>
              <div style={{ textAlign: "center" }}>stress-free</div>
            </div>
          </div>
        </Content>
        <Footer
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            lineHeight: "300%",
          }}
        >
          <div>Copyright © 2021</div>
          <div>Home365 Property Management</div>
          {/* <div>Terms and Conditions</div> */}
        </Footer>
      </Layout>
    </div>
  );
};

export default App;
